











































import { Cashback, ItemCashback } from '@/models/Cashback';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindCashbackUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify';

@Component
export default class ListagemDeItemCashback extends Vue {

	headersItensCashback = [
		{ text: 'Nome do produto', value: 'nomeDoProduto' },
		{ text: 'Sku', value: 'sku'},
		{ text: 'Data para recebimento', value: 'dataParaRecebimento'},
		{ text: 'Valor creditado', value: 'valorCreditado'},
		{ text: 'Data para expirar', value: 'dataParaExpirarFormatado'},
	]
	
	itensCashback: ItemCashback[] = []
	cancelToken: CancelTokenSource | null = null
	cashback!: Cashback

	findCashBackUseCase = new FindCashbackUseCase()

	totalRegistros = -1

	loading = false
	mostra = false

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	@Watch('mostra')
	onChangeMostra() {
		if (this.mostra) this.buscarItensCashback()
		if(!this.mostra) {
			this.itensCashback = []
			this.paginacao = {
				page: 0,
				itemsPerPage: 5,
				sortBy: [],
				sortDesc: [],
				groupBy: [],
				groupDesc: [],
				multiSort: false,
				mustSort: false,
			}
			this.totalRegistros = -1
		}
	}

	async mostrar(cashback: Cashback) {
		this.cashback = cashback
		this.itensCashback = []
		this.mostra = true
		this.buscarItensCashback()
		
	}

	@Watch('paginacao')
	async buscarItensCashback(){
		if(!this.mostrar) return 
		this.loading = true
		try {
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				cashbackId: this.cashback.id || undefined,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const itens = await this.findCashBackUseCase.findItensCashback(params, axiosConfig)
			this.itensCashback = itens.content
			this.totalRegistros = itens.totalElements
			this.loading = false
		} catch(error) {
			AlertModule.setError(error)
			this.loading = false
		}
	}

	get listaDeItensCashbackFormatada() {
		return this.itensCashback.map(item => ({
			...item,
			sku: item.produto.sku,	
			nomeDoProduto: item.produto.nome,
			dataParaExpirarFormatado: item.dataParaExpirar ? item.dataParaExpirar : '-  -',
		}))
	}
}

