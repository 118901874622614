



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify';
import Axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';
import type { Page } from '@/models';
import { Pageable } from '@/models/Pageable';
import { sortConverter } from '@/shareds/paginacao';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { obterPaginaVazia, obterPaginavelVazio } from '@/shareds/utils-paginacao';

@Component
export default class DataTablePaginado extends Vue {
	@Prop({ type: Function }) onBuscar!: OnBuscarParam
	@Prop({ type: Object, default: null }) filtros!: any
	@Prop({ type: Boolean, default: false }) manterPaginas!: boolean

	options: DataOptions | null = null

	carregando = false
	cancelToken: CancelTokenSource | null = null

	paginavel: Pageable = obterPaginavelVazio()
	pagina: Page<any> = obterPaginaVazia()
	itens: any[] = []

	created() {
		this.buscar()
	}

	@Watch('options')
	onChangeOptions(options: DataOptions, optionsAnterior: DataOptions | null) {
		this.paginavel.size = options.itemsPerPage
		this.paginavel.sort = sortConverter(options)
		if (optionsAnterior && (
			options.sortBy.join() !== optionsAnterior.sortBy.join() ||
			options.sortDesc.join() !== optionsAnterior.sortDesc.join() ||
			options.itemsPerPage !== optionsAnterior.itemsPerPage
		)) {
			this.paginavel.page = 0
			if (this.options) this.options.page = 1
			this.pagina = obterPaginaVazia()
			this.buscar()
			return
		}
		this.paginavel.page = options.page -1
		this.buscar()
	}

	async buscar() {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = Axios.CancelToken.source()
			this.carregando = true
			const pagina = await this.onBuscar({
				...this.paginavel,
				...this.filtros,
			}, {
				cancelToken: this.cancelToken.token,
			})
			if (this.manterPaginas) {
				this.pagina = {
					...pagina,
					content: [
						...this.pagina.content,
						...pagina.content,
					],
				}
			} else {
				this.pagina = pagina
			}
			this.carregando = false
		} catch (error: any) {
			if (Axios.isCancel(error)) return
			AlertModule.setError(error)
			this.pagina = obterPaginaVazia()
			this.carregando = false
			throw error
		}
	}

	@Watch('filtros')
	onChangeFiltros() {
		this.reiniciar()
	}

	@Watch('carregando')
	onChangeCarregando(carregando: boolean) {
		this.$emit("update:carregando", carregando)
	}

	@Watch('pagina')
	onChangePagina(pagina: Page<any>) {
		this.$emit("update:pagina", pagina)
	}

	reiniciar() {
		this.paginavel = obterPaginavelVazio()
		this.pagina = obterPaginaVazia()
		this.buscar()
	}
	
}

export type OnBuscarParam = (params?: any, config?: AxiosRequestConfig) => Promise<Page<any>>;

