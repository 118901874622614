




































































import { uploadFilesToS3 } from '@/shareds/s3/files';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { CashbackUseCase } from '@/usecases/cliente/CashbackUseCase';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DialogoDeCsvCashback extends Vue {
	mostra = false
	tab: null | number = null
	tabs = [
		{ action: 'Importar', icon: 'mdi-file-upload' },
	]
	arquivoContemCabecalho = false
	importando = false
	exportando = false
	arquivoCsv: File[] = []
	cashbackUseCase = new CashbackUseCase()

	mostrar(){
		this.mostra = true
	}

	async concluirImportacao() {
		if (this.arquivoCsv.length == 0) {
			return AlertModule.setError("É obrigatório selecionar um arquivo");
		}
	
		try {
			this.importando = true;

			const informacoesDosArquivos = await uploadFilesToS3(
				this.arquivoCsv,
				`importacao-cashback`,
			);
			const urlDoArquivo = informacoesDosArquivos.map(({ config }) => config.url)[0];

			if (!urlDoArquivo) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}

			await this.cashbackUseCase.importarCashback(
				this.arquivoContemCabecalho,
				urlDoArquivo,
			);

			AlertModule.setSuccess('Cashbacks em processamento');
			this.$emit('concluiuImportacao');
		} catch (e) {
			this.importando = false;
			AlertModule.setError(e);
		} finally {
			this.importando = false;
			this.arquivoCsv = [];
			this.mostra = false;
			this.arquivoContemCabecalho = false;
		}
	}

	cancelar() {
		this.importando = false
		this.mostra = false
		this.arquivoCsv = []
		this.arquivoContemCabecalho = false
	}
}
