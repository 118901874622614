var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',_vm._g(_vm._b({attrs:{"items":_vm.listaDeCashbackFormatada,"headers":_vm.headers,"loading":_vm.carregando,"server-items-length":_vm.totalRegistros,"options":_vm.paginacao,"manterPaginas":"","must-sort":""},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.statusDeCashback",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.statusDeCashback)},[_vm._v(" "+_vm._s(item.statusDeCashback !== 'Concluido' ? item.statusDeCashback : 'Creditado')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.hideDetail)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","loading":_vm.loading},on:{"click":function () { return _vm.dialogoDeListagemDeItemCashback.mostrar(item); }}},Object.assign({}, tooltip)),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_vm._v(" Detalhar cashback ")]),(item.statusDeCashback === 'Pendente' && _vm.temPermissao())?_c('Confirmacao',{attrs:{"width":"300","titulo":"Cancelar Venda?","subtitulo":"<div>" + (item.dataHora) + "</div>"+
						"<br/>"+
						"<div>Tem certeza que deseja cancelar esse cashback? </div>"},on:{"confirmar":function () { return _vm.cancelarCashback(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var dialog = ref.on;
return [(item.statusDeCashback === 'Pendente' && _vm.temPermissao())?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var tooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","disabled":_vm.cancelando.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.cancelando.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-briefcase-remove')+" ")])]}}],null,true)},[_vm._v(" Cancelar Cashback ")]):_vm._e()]}}],null,true)}):_vm._e()],1)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('ListagemDeItemCashback',{ref:"dialogoDeListagemDeItemCashback"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }