import { Cashback } from "@/models";
import { Inject } from "inversify-props";
import type { CashbackServiceAdapter } from "./adapter";

export class CancelarCashbackUseCase {
	@Inject('CashbackServiceAdapter')
	private cashBackServiceAdapter!: CashbackServiceAdapter

	cancelar = async (id: string): Promise<Cashback> => {
		return await this.cashBackServiceAdapter.cancelar(id)
	}
}