


































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import { Pageable } from '@/models'
import { FindGrupoEconomicoUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import type { GrupoEconomico } from '@/models/GrupoEconomico'
import { Fragment } from 'vue-fragment'
import { VListItemContent } from 'vuetify/lib'

@Component({
	components: {
		Fragment,
		VListItemContent,
	},
})
export default class BuscaDeGrupoEconomico extends Vue  {
	@PropSync('value') selecionado!: GrupoEconomico
	@Prop() encurtado!: boolean
	busca = ''
	findGrupoEconomicoUseCase = new FindGrupoEconomicoUseCase()
	gruposEconomicos: GrupoEconomico[] = []
	cancelToken: CancelTokenSource | null = null
	carregando = false

	page: Pageable = {
		page: 1,
		size: 5,
		sort: [],
	}

	totalPages = 1


	async created() {
		await this.findGruposEconomicos()
	}


	async findGruposEconomicos() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const gruposEconomicosPage = await this.findGrupoEconomicoUseCase.findByParams({
				page: this.page.page ? this.page.page - 1 : 0,
				size: 5,
				busca: this.busca || undefined,
			}, axiosConfig )


			this.totalPages = gruposEconomicosPage.totalPages
			this.gruposEconomicos = gruposEconomicosPage.content
			this.carregando = false
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	@Watch('busca')
	onChangeBusca() {
		this.gruposEconomicos = []
		this.page.page = 1
		this.findGruposEconomicos()
	}

	@Watch('page', { deep: true, immediate: true })
	onChangePageable() {
		this.gruposEconomicos = []
		this.findGruposEconomicos()
	}
}

