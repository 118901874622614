import { Inject } from "inversify-props";
import type { CashbackServiceAdapter } from "./adapter";

export class CashbackUseCase {
	@Inject('CashbackServiceAdapter')
	private cashBackServiceAdapter!: CashbackServiceAdapter

	async importarCashback(arquivoContemCabecalho: boolean, urlDoArquivo: string): Promise<void> {
		this.cashBackServiceAdapter.importarCashback(arquivoContemCabecalho, urlDoArquivo)
	}
}